
import React, { useEffect, useState } from 'react';


interface IAdminDashboard {

}

const AdminDashboard: React.FC<IAdminDashboard> = (props) => {

	return (
	  <>
      Dashboard Placeholder
	  </>
  )
};

export default AdminDashboard;
